import React from 'react';

function MedicineContent(props) {
  const medicine = props.medicine;
  const medicines = props.medicines.filter(m => Object.keys(m)[0] === medicine);
  return medicines.map((medicine, mi) => {
    return (
      <div key={'ma' + mi}>
        <div className="medicineName">{Object.keys(medicine)[0]}</div>
        <div className="medicineAttributes">
          {
            Object.values(medicine)[0].map((attribute, ai) => {
              if (typeof attribute === 'string')
                return <div key={'mav' + ai} className="attributeValue">{attribute}</div>;
              const value = Object.values(attribute)[0];
              if (value === '')
                return null;
              return (
                <div key={'mav' + ai}>
                  <div className="attributeName">【{Object.keys(attribute)[0]}】</div>
                  <div className="attributeValue">{value}</div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  });
}

export default MedicineContent;
