import React from 'react';
import LongList from './LongList';

function MedicineList(props) {
  const medicines = props.medicines;

  const onClick = (event) => {
    props.onSelect(event.target.innerText);
  };

  const renderer = (index) => {
    return <div key={'m' + index} onClick={onClick}>{medicines[index]}</div>;
  };

  return (
    <LongList className={props.className} style={props.style}
      total={medicines.length}
      itemHeight={32}
      renderer={renderer}
    />
  );
}

export default MedicineList;
