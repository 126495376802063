import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Select } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import MedicineList from './MedicineList';

const types = ['全部', '補養', '發表', '涌吐', '攻裡', '表裡', '和解', '理氣', '理血', '袪風', '袪寒', '清暑', '利濕', '潤燥', '瀉火', '除痰', '消導', '收濇', '殺蟲', '明目', '癰瘍', '經產', '鎮靜', '開竅'];
const typeOptions = types.map((value, index) => <MenuItem key={index} value={index}>{value}</MenuItem>);

function ListTab(props) {
  const [medicines, setMedicines] = useState([]);
  const [name, setName] = useState('');
  const [type, setType] = useState(0);

  const onNameChanged = (event) => {
    setName(event.target.value);
  };

  const onTypeChanged = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    let array = props.medicines;
    if (name !== '')
      array = array.filter(medicine => Object.keys(medicine)[0].indexOf(name) !== -1);
    if (type !== 0) {
      const typeName = types[type] + '之劑';
      array = array.filter(medicine => Object.values(medicine)[0][0]['劑型'] === typeName);
    }
    array = array.map(medicine => Object.keys(medicine)[0]).sort();
    setMedicines(array.filter((medicine, index) => index === 0 || medicine !== array[index - 1]));
  }, [props.medicines, name, type]);

  const style = props.show ? null : {display:'none'};

  return (
    <>
      <div className="toolbar" style={style}>
        <SearchOutlined fontSize='large' /><TextField type='search' placeholder="輸入藥名" variant='outlined' style={{flexGrow:'1'}} onChange={onNameChanged} />
        <Select value={type} variant='outlined' onChange={onTypeChanged}>{typeOptions}</Select>
      </div>
      <MedicineList className="fill" medicines={medicines} style={style} onSelect={props.onSelect} />
    </>
  );
}

export default ListTab;
