import React from 'react';
import MedicineList from './MedicineList';

function BookmarksTab(props) {
  const style = props.show ? null : {display:'none'};

  return (
    <MedicineList className="fill" medicines={props.medicines} style={style} onSelect={props.onSelect} />
  );
}

export default BookmarksTab;
