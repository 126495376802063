import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Menu, MenuItem, Modal, Tab, Tabs } from '@material-ui/core';
import { HistoryOutlined, InfoOutlined, MenuOutlined, Star, StarBorder } from '@material-ui/icons';
import ListTab from './ListTab';
import HistoryTab from './HistoryTab';
import BookmarksTab from './BookmarksTab';
import MedicineContent from './MedicineContent';
import './App.css';

const appName = '中藥典';

function App() {
  const [tab, setTab] = useState(0);
  const [medicine, setMedicine] = useState(null);
  const [systemMenu, setSystemMenu] = useState(null);
  const [about, setAbout] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [history, setHistory] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const medicineNum = useRef(0);

  const updateHistory = (medicine) => {
    const index = history.indexOf(medicine);
    if (index !== 0) {
      let value = Array.from(history);
      if (index !== -1)
        value.splice(index, 1);
      value.unshift(medicine);
      if (value.length > 100)
        value.pop();
      localStorage.setItem('history', value.join(','));
      setHistory(value);
    }
  };

  const onTabChanged = (event, value) => {
    setTab(value);
  };

  const onSelect = (value) => {
    setMedicine(value);
    updateHistory(value);

    window.history.pushState({medicine: value}, '');
  };

  const renderTabs = (show) => {
    const style = show ? null : {display:'none'};
    return (
      <>
        <Tabs value={tab} centered={true} style={style} onChange={onTabChanged}>
          <Tab icon={<InfoOutlined />} label='列表' />
          <Tab icon={<HistoryOutlined />} label='歷史' />
          <Tab icon={<StarBorder />} label='書籤' />
        </Tabs>
      </>
    );
  };

  const onAboutClosed = () => {
    window.history.back();
  };

  const renderAbout = useCallback(() => {
    return (
      <Modal
          open={true}
          onClose={onAboutClosed}
          style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <div style={{background:'white', padding:'20px'}}>
          <div style={{fontStyle:'bold', fontSize:'1.5em', marginBottom:'10px'}}>關於</div>
          <div>收錄 {medicineNum.current} 種藥材與藥方</div>
          <div><a href="https://play.google.com/store/apps/details?id=com.luncky.chinesemedicine" target="_blank" rel="noreferrer">下載 Android App</a></div>
        </div>
      </Modal>
    );
  }, []);

  const onContactClicked = () => {
    setSystemMenu(null);
    const url = new URL('mailto:chinesemedicine@luncky.com');
    url.searchParams.append('subject', '[' + appName + ']');
    window.open(url, '_blank');
  };

  const onAboutClicked = () => {
    setSystemMenu(null);
    setAbout(renderAbout());
    window.history.pushState({about:true}, '');
  };

  const onSystemMenuClosed = () => {
    setSystemMenu(null);
  };

  const renderSystemMenu = (anchor) => {
    return (
      <Menu open={true} anchorEl={anchor} getContentAnchorEl={null} anchorOrigin={{vertical:'bottom'}} onClose={onSystemMenuClosed}>
        <MenuItem onClick={onContactClicked} >聯繫作者</MenuItem>
        <MenuItem onClick={onAboutClicked} >關於</MenuItem>
      </Menu>
    );
  };

  const onSystemMenuButtonClicked = (event) => {
    if (systemMenu)
      setSystemMenu(null);
    else
      setSystemMenu(renderSystemMenu(event.currentTarget));
  };

  const onBookmarkClicked = () => {
    const index = bookmarks.indexOf(medicine);
    let value = null;
    if (index === -1)
      value = bookmarks.concat(medicine);
    else
      value = bookmarks.slice(0, index).concat(bookmarks.slice(index + 1));
    localStorage.setItem('bookmarks', value.join(','));
    setBookmarks(value);
  };

  useEffect(() => {
    const host = window.location.hostname;
    if (host === 'localhost' || host === '127.0.0.1' || host.startsWith('192.168.') || host.endsWith('luncky.com')) {
      const base = window.location.origin + window.location.pathname;
      const files = ['cmdb', 'kuoko_s', 'kuoko_c', 'tian-i_s', 'tian-i_c', 'wankuo', 'yifang'];
      Promise.all(files.map(file => fetch(base + 'data/' + file + '.json')))
        .then(resps => {
          return Promise.all(resps.map(resp => resp.json()));
        })
        .then(jsons => {
          let array = [];
          jsons.forEach(json => array = array.concat(json));
          setMedicines(array);

          array = array.map(medicine => Object.keys(medicine)[0]).sort();
          array = array.filter((medicine, index) => index === 0 || medicine !== array[index - 1]);
          medicineNum.current = array.length;
        });
    }

    let value = localStorage.getItem('history');
    if (value !== null)
      setHistory(value.split(','));

    value = localStorage.getItem('bookmarks');
    if (value != null)
      setBookmarks(value.split(','));
  }, []);

  const onPopState = useCallback((event) => {
    const state = event.state;
    if (state && state['medicine']) {
      setSystemMenu(null);
      setAbout(null);
      setMedicine(state['medicine']);
    } else if (state && state['about']) {
      setSystemMenu(null);
      setAbout(renderAbout());
    } else {
      setSystemMenu(null);
      setAbout(null);
      setMedicine(null);
    }
  }, [renderAbout]);

  useEffect(() => {
    window.addEventListener('popstate', onPopState);
  }, [onPopState]);

  const star = (medicine && bookmarks.indexOf(medicine) !== -1) ? <Star /> : <StarBorder />;

  return (
    <div className="App">
      <div id="header">
        <IconButton onClick={onSystemMenuButtonClicked}><MenuOutlined /></IconButton>
        <span>{appName}</span>
        {medicine && <IconButton onClick={onBookmarkClicked} style={{position:'absolute', right:'8px'}}>{star}</IconButton>}
      </div>
      <div id="body" className="fill">
        {renderTabs(!medicine)}
        <ListTab medicines={medicines} show={!medicine && tab === 0} onSelect={onSelect} />
        <HistoryTab medicines={history} show={!medicine && tab === 1} onSelect={onSelect} />
        <BookmarksTab medicines={bookmarks} show={!medicine && tab === 2} onSelect={onSelect} />
        {medicine && <div className="fill"><MedicineContent medicines={medicines} medicine={medicine} /></div>}
      </div>
      {systemMenu}
      {about}
    </div>
  );
}

export default App;
