import React, { useEffect, useState } from 'react';

function LongList(props) {
  const { total, itemHeight, renderer } = props;
  const [firstRender, setFirstRender] = useState(0);
  const [renderNum, setRenderNum] = useState(1);
  const [data, setData] = useState([]);
  const [top, setTop] = useState(0);

  const onScroll = (event) => {
    setFirstRender(Math.max(0, Math.floor(event.target.scrollTop / itemHeight) - 30));
  };

  useEffect(() => {
    setRenderNum(Math.min(total - firstRender, Math.ceil(window.innerHeight / itemHeight) + 60));
  }, [total, firstRender, itemHeight]);

  useEffect(() => {
    let array = new Array(renderNum);
    for (let i = 0; i < renderNum; ++i)
      array[i] = renderer(firstRender + i);
    setData(array);
    setTop(firstRender * itemHeight);
  }, [firstRender, renderNum, itemHeight, renderer]);

  return (
    <div className={props.className} style={{...props.style, overflow:'auto'}} onScroll={onScroll}>
      <div style={{position:'relative', height:total * itemHeight, overflow:'hidden'}}>
        <div className='listContent' style={{position:'absolute', top:top, width:'100%'}}>
          {data}
        </div>
      </div>
    </div>
  );
}

export default LongList;
